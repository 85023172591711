import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Peta = () => import('@/views/peta/peta')
const Daftar = () => import('@/views/peta/daftar')

const User = () => import('@/views/user/listuser')
const TambahUsers = () => import('@/views/user/tambahuser')
const EditUsers = () => import('@/views/user/edituser')

const Instansi = () => import('@/views/instansi/listinstansi')
const TambahInstansi = () => import('@/views/instansi/tambahinstansi')
const EditInstansi = () => import('@/views/instansi/editinstansi')

const Verifikasi = () => import('@/views/verifikasi/listverifikasi')
// const TambahVerifikasi = () => import('@/views/verifikasi/tambahverifikasi')
const EditVerifikasi = () => import('@/views/verifikasi/editverifikasi')

const Verlap = () => import('@/views/verlap/listverlap')
// const TambahVerlap = () => import('@/views/verlap/tambahverlap')
const EditVerlap = () => import('@/views/verlap/editverlap')

const Validasi = () => import('@/views/validasi/listvalidasi')
// const TambahValidasi = () => import('@/views/validasi/tambahvalidasi')
const EditValidasi = () => import('@/views/validasi/editvalidasi')

const Asetopd= () => import('@/views/asetopd/listasetopd')
const TambahAsetopd = () => import('@/views/asetopd/tambahasetopd')
const EditAsetopd = () => import('@/views/asetopd/editasetopd')

const Asetwilayah= () => import('@/views/asetwilayah/listasetwilayah')
const TambahAsetwilayah = () => import('@/views/asetwilayah/tambahasetwilayah')
const EditAsetwilayah = () => import('@/views/asetwilayah/editasetwilayah')
const DetailAsetwilayah = () => import('@/views/asetwilayah/detailasetwilayah')

const Hakdiatashak= () => import('@/views/hakdiatashak/listhakdiatashak')
const DetailHakdiatashak = () => import('@/views/hakdiatashak/detailhakdiatashak')

const Asetunregis= () => import('@/views/asetunregis/listasetunregis')
const TambahAsetunregis = () => import('@/views/asetunregis/tambahasetunregis')
const EditAsetunregis = () => import('@/views/asetunregis/editasetunregis')
const DetailAsetunregis = () => import('@/views/asetunregis/detailasetunregis')

// const Laporan = () => import('@/views/laporan/laporan')
const LaporanDinas = () => import('@/views/laporan/laporandinas')
const LaporanWilayah = () => import('@/views/laporan/laporanwilayah')
const LaporanHak = () => import('@/views/laporan/laporanhak')
const Profilku = () => import('@/views/profilku/profilku')
const UbahPassword = () => import('@/views/ubahpassword/ubahpassword')

const Pos = () => import('@/views/Pos')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Sigagah',
      component: TheContainer,
      meta: {
        guest: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'peta',
          name: 'Peta',
          component: Peta,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'hakdiatashak',
          redirect: '/hakdiatashak/list',
          name: 'Hak diatas Hak',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Data Aset Hak diatas Hak',
              component: Hakdiatashak,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:id_hak_diatas_hak',
              name: 'Detail Aset Hak diatas Hak',
              component: DetailHakdiatashak,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'daftar-alas-hak',
          name: 'Daftar Alas Hak',
          component: Daftar,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'profilsaya',
          name: 'Profilku',
          component: Profilku,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'laporandinas',
          name: 'Laporan per Dinas',
          component: LaporanDinas,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'laporanwilayah',
          name: 'Laporan per Wilayah',
          component: LaporanWilayah,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'laporanhak',
          name: 'Laporan per Hak',
          component: LaporanHak,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'asetopd',
          redirect: '/asetopd/list',
          name: 'Aset Opd',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Data Aset Opd',
              component: Asetopd,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Tambah Aset Opd',
              component: TambahAsetopd,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_asetopd',
              name: 'Edit Aset Opd',
              component: EditAsetopd,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'instansi',
          redirect: '/instansi/list',
          name: 'Instansi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Data Instansi',
              component: Instansi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Tambah Instansi',
              component: TambahInstansi,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_instansi',
              name: 'Edit Instansi',
              component: EditInstansi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        
        {
          path: 'asetwilayah',
          redirect: '/asetwilayah/list',
          name: 'Aset Bersertifikat',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Data Aset Bersertifikat',
              component: Asetwilayah,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Tambah Aset Bersertifikat',
              component: TambahAsetwilayah,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_aset_bersertifikat',
              name: 'Edit Aset Bersertifikat',
              component: EditAsetwilayah,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:id_aset_bersertifikat',
              name: 'Detail Aset Bersertifikat',
              component: DetailAsetwilayah,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'asetunregis',
          redirect: '/asetunregis/list',
          name: 'Aset Belum Bersertifikat',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Data Aset',
              component: Asetunregis,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Tambah Aset',
              component: TambahAsetunregis,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_aset_bersertifikat',
              name: 'Edit Aset',
              component: EditAsetunregis,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:id_aset_bersertifikat',
              name: 'Detail Aset',
              component: DetailAsetunregis,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'verifikasi',
          redirect: '/verifikasi/list',
          name: 'Verifikasi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Verifikasi',
              component: Verifikasi,
              meta: {
                requiresAuth: true
              }
            },
            // {
            //   path: 'add',
            //   name: 'Add Verifikasi',
            //   component: TambahVerifikasi,
            //   meta: {
            //     requiresAuth: true
            //   }
            // },
            {
              path: 'edit/:id_verlap',
              name: 'Edit Verifikasi',
              component: EditVerifikasi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'verlap',
          redirect: '/verlap/list',
          name: 'Verifikasi Lapangan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Verifikasi Lapangan',
              component: Verlap,
              meta: {
                requiresAuth: true
              }
            },
            // {
            //   path: 'add',
            //   name: 'Add Verifikasi Lapangan',
            //   component: TambahVerlap,
            //   meta: {
            //     requiresAuth: true
            //   }
            // },
            {
              path: 'edit/:id_verlap',
              name: 'Edit Verifikasi Lapangan',
              component: EditVerlap,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'validasi',
          redirect: '/validasi/list',
          name: 'Validasi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Validasi',
              component: Validasi,
              meta: {
                requiresAuth: true
              }
            },
            // {
            //   path: 'add',
            //   name: 'Add Validasi',
            //   component: TambahValidasi,
            //   meta: {
            //     requiresAuth: true
            //   }
            // },
            {
              path: 'edit/:id_verlap',
              name: 'Edit Validasi',
              component: EditValidasi,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: '/user/list',
          name: 'Users',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Users',
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add User',
              component: TambahUsers,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit User',
              component: EditUsers,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pencarian',
    //   name: 'Pencarian',
    //   component: Pencarian,
    //   meta: {
    //     guest: true
    //   }
    // },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }
  ]
}

// Meta Handling


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') === '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       let user = JSON.parse(localStorage.getItem('user'))
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       if (to.matched.some(record => record.meta.is_admin)) {
//         next({ path: '/dashboard' })
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       next({ path: '/dashboard' })

//     }
//   } else {
//     next()
//   }
// })

const redirectToLogin = route => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true, query: { redirectFrom: route.fullPath } };
  }
};

const redirectToHome = route => {
  const DEFAULT = 'Dashboard';
  return { name: DEFAULT, replace: true };
};

const DEFAULT_TITLE = 'SiGagah';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == "") {
  //     router.push({ path: '/login' });
  //   } else {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     if (user == null) {
  //       if (user.level == 1) {
  //         router.push({ path: '/dashoard' });
  //       } else {
  //         // next({ path: '/dashboard' })
  //         router.push({ path: '/dashoard' });
  //       }
  //     } else {
  //       router.push({ path: '/login' });
  //     }
  //   }
  // } else if (to.matched.some(record => record.meta.guest)) {
  //   if (localStorage.getItem('jwt') == null) {
  //     router.push({ path: '/login' });
  //   } else {
  //     next({ path: '/dashboard' })
  //   }
  // } else {
  //   router.push({ path: '/login' });
  // }

  const userIsAuthenticated = localStorage.getItem('jwt');
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  // if (localStorage.getItem('jwt') == null) {
  //   router.push({ path: '/login' });
  // } else {
  //   next({ path: '/dashboard' })
  // }
  // if (!userIsAuthenticated && to.fullPath === '/') {
  //   alert('ERROR1');
  //   return redirectToLogin(to);
  // }
  // alert(to.path);
  if (to.path == '/register') {
    next();
  }
  // else if (to.path == '/pencarian') {
  //   next();
  // }
  else if (to.path !== '/login') {
    if (requiresAuth) {
      if (!userIsAuthenticated) {
        alert('Maaf! Anda harus login terlebih dahulu');
        router.push({ path: '/login' });
      }
      else {
        // alert('ERROR1');
        next();
      }
    }
    else {
      // alert('ERROR3');
      next({ path: '/login' })
    }


    return true;
  }
  else {
    next()
  }
});

export default router;

