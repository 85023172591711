import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const state = {
  sidebarShow: 'responsive',
  isLogin : false,
  labelhead : "Dashboard",
  users : [],
  sidebarMinimize: false,
  properties:{},
  coordinates:[]
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setLogin (state, bool) {
    state.isLogin = bool;
  },
  setLabelhead (state, param) {
    state.labelhead = param;
  },
  setUsers (state, data) {
    state.users = data;
  },
  setProp (state, data) {
    state.properties = data;
  },
  setCoord (state, data) {
    state.coordinates = data;
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations,
  plugins: [vuexLocal.plugin]
})